<template>
  <div class="layout-main">
    <router-view></router-view>
    <app-bar/>
  </div>
</template>

<script>
export default {
  name: 'MainLayout',
}
</script>

<style lang="scss">
.layout-main{
  padding-top: calc(env(safe-area-inset-top) + 70px);
  padding-bottom: calc(env(safe-area-inset-bottom) + 96px);
}
</style>
